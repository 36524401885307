<script setup lang="ts">
  defineProps<{
    large?: boolean;
    extraLarge?: boolean;
    centered?: boolean;
  }>();

  const organizationCard = useOrganizationCard.inject();

  const logo = computed(() => {
    if (
      organizationCard.organization &&
      organizationCard.organization !== 'incognito' &&
      organizationCard.organization.logoUrl &&
      organizationCard.organization.logoUrl.length > 0
    ) {
      return {
        src: organizationCard.organization.logoUrl,
        alt: organizationCard.organization.name,
      };
    }
  });
  const isIncognito = computed(() => organizationCard.organization === 'incognito');
</script>

<template>
  <div
    v-if="logo || isIncognito"
    :class="{
      'logo-container': true,
      'logo-container--large': large,
      'logo-container--extra-large': extraLarge,
      'logo-container--centered': centered,
    }"
  >
    <img
      v-if="logo"
      class="logo-container__image"
      loading="lazy"
      :src="logo.src"
      :alt="logo.alt"
      :width="extraLarge ? 208 : large ? 160 : 128"
      :height="extraLarge ? 144 : large ? 104 : 80"
    />
    <Illustration
      v-else-if="isIncognito"
      class="logo-container__image"
      image="job-for-client"
      :height="40"
      :width="160"
    />
  </div>
</template>

<style scoped lang="scss">
  .logo-container {
    $self: &;
    --width: 8rem;
    --height: 5rem;

    &--centered {
      #{$self}__image {
        display: block;
        margin: auto;
      }
    }

    &--large {
      --width: 10rem;
      --height: 6.5rem;
    }

    &--extra-large {
      --width: 13rem;
      --height: 9rem;
    }

    &__image {
      object-fit: contain;
      object-position: left;
      width: fit-content;
      height: fit-content;
      max-width: var(--width);
      max-height: var(--height);
    }
  }
</style>
